html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background-color: #121212 !important;
}

html,
body {
  overscroll-behavior: none;
}
